export const TYPE_INPUT = 'input';
export const TYPE_TEXTAREA = 'textarea';
export const TYPE_EMAIL = 'email';
export const TYPE_TEL = 'tel';
export const TYPE_NUMBER = 'number';

export const TYPE_AUTOCOMPLETE_ONE_TIME_CODE = 'one-time-code';

export const TYPES = { TYPE_INPUT, TYPE_TEXTAREA };

export const THEME_DEFAULT = 'default';
export const THEME_ESIZEME = 'esizeme';

export const THEMES = { THEME_DEFAULT, THEME_ESIZEME };
